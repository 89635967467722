import Repository from './repository'

const resource = '/v1/auth'

export default {
  login (dados) {
    return Repository.post(`${resource}/login`, dados)
  },
  permissoes () {
    return Repository.post(`${resource}/permissoes`)
  },
  logout () {
    return Repository.post(`${resource}/logout`)
  },
  refreshToken() {
    return Repository.post(`${resource}/refresh-token`)
  }
}
